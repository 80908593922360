import { render, staticRenderFns } from "./ButtonBorder.vue?vue&type=template&id=45d3d208&"
import script from "./ButtonBorder.vue?vue&type=script&lang=js&"
export * from "./ButtonBorder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports